* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

.icon {
  cursor: pointer;
  padding-bottom: 5px;
  padding: 5px;
  margin-left: 5px;
  margin-top: 4vh;
  height: 5vh;
  width: auto;
  color:#277F91;
  transition: all 0.4s ease 0s;
  border: solid 1px #277F91;
  box-shadow: 2px 1px 2px rgb(0 0 0 / 40%);
  -webkit-box-shadow: 2px 1px 2px rgb(0 0 0 / 40%);
  border-radius: 20px;
}

.icon:hover {
  color:#383F42;
  transition: all 0.4s ease 0s;
}

.nav-link .nav-text {
  display: grid;
  transform: translateX(-100%);
  width: auto;
  color:#75A1BD;
  transition: all 0.4s ease 0s;
  font-size: 2rem;
  
}

.nav-link .nav-text {
  visibility: hidden;
}

a {
  text-decoration: none;
  color: green;
}

.nav-link:hover .nav-text{
  transform: translateX(0);
  visibility: visible;
  color:#277F91;
  transition: all 0.4s ease 0s;
}




